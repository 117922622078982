import { endOfDay, format, parse, sub } from 'date-fns';

import { DefaultDateFormatEnum } from '../../enums/default-date-format-types/default-date-format.enum';

export class DateUtil {
  public static formatDateToString(date: Date, dateFormat: string = DefaultDateFormatEnum.app): string {
    if (!date) {
      return '';
    }

    return format(date, dateFormat);
  }

  public static yesterday(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 1);

    return date;
  }

  public static today(): Date {
    return new Date();
  }

  public static parse(value: string, dateFormat: string = DefaultDateFormatEnum.app): Date {
    return parse(value, dateFormat, new Date());
  }

  public static parseFullDay(value: Date): Date {
    return endOfDay(value);
  }

  public static convertToApiFormat(value: string, fullDay?: boolean): string {
    const defaultDateFormat: DefaultDateFormatEnum = DefaultDateFormatEnum.api;

    return DateUtil.convertToNewFormat(value, defaultDateFormat, fullDay);
  }

  public static getDateGivenPeriodAgoFromToday(duration: Duration): Date {
    const dateToday: Date = DateUtil.today();

    return sub(dateToday, duration);
  }

  private static convertToNewFormat(value: string, newFormat: DefaultDateFormatEnum, fullDay?: boolean): string {
    const date: Date = DateUtil.parse(value);

    return fullDay ? DateUtil.formatDateToString(DateUtil.parseFullDay(date), newFormat) : DateUtil.formatDateToString(date, newFormat);
  }
}
